import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-time-table-summary-hours',
  templateUrl: './time-table-summary-hours.component.html',
  styleUrls: ['./time-table-summary-hours.component.scss']
})
export class TimeTableSummaryHoursComponent implements OnInit {
  months: Array<string> = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
  cols: Array<number> = [];

  constructor() {
  }

  ngOnInit() {
    for (let i = 0; i < 13; i++) {
      this.cols.push(i);
    }
  }
}
