import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-table-summary-table',
  templateUrl: './time-table-summary-table.component.html',
  styleUrls: ['./time-table-summary-table.component.scss']
})
export class TimeTableSummaryTableComponent implements OnInit {
  cols: Array<number> = [];

  constructor() {
  }

  ngOnInit() {
    for (let i = 0; i < 13; i++) {
      this.cols.push(i);
    }
  }
}
