import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PageComponent } from './components/page/page.component';
import { TimeTableComponent } from './time-table/time-table.component';
import { TimeTableHeaderComponent } from './time-table-header/time-table-header.component';
import { TimeTableSummaryHeaderComponent } from './time-table-summary-header/time-table-summary-header.component';
import { TimeTableHoursComponent } from './time-table-hours/time-table-hours.component';
import { TimeTableSummaryHoursComponent } from './time-table-summary-hours/time-table-summary-hours.component';
import { TimeTableSummaryTextComponent } from './time-table-summary-text/time-table-summary-text.component';
import { TimeTableSummaryTableComponent } from './time-table-summary-table/time-table-summary-table.component';
import { TimeTableRemarksComponent } from './time-table-remarks/time-table-remarks.component';
import { InstallmentComponent } from './installment/installment.component';

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    TimeTableComponent,
    TimeTableHeaderComponent,
    TimeTableSummaryHeaderComponent,
    TimeTableHoursComponent,
    TimeTableSummaryHoursComponent,
    TimeTableSummaryTextComponent,
    TimeTableSummaryTableComponent,
    TimeTableRemarksComponent,
    InstallmentComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
