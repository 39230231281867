import {Component, OnInit} from '@angular/core';
import {daysInMonth, getNotWorkingDay} from "../utils";

@Component({
  selector: 'app-time-table-hours',
  templateUrl: './time-table-hours.component.html',
  styleUrls: ['./time-table-hours.component.scss']
})
export class TimeTableHoursComponent implements OnInit {
  days: Array<number> = [];
  months: Array<string> = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
  daysInMonth: { [key: number]: number } = {};
  notWorkingDays: Array<number> = [];
  year: number = 2020;

  constructor() {
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
      
    for (let i = 1; i < 32; i++) {
      this.days.push(i);
    }

    for (let i = 1; i < 13; i++) {
      this.daysInMonth[i] = daysInMonth(this.year, i);
    }

    this.notWorkingDays = getNotWorkingDay(this.year);
  }

  getDayClass(month: string, day: number) {
    let monthNumber = this.months.indexOf(month) + 1;

    if (this.daysInMonth[monthNumber] < day) {
      return "empty-day";
    }

    let date = new Date(this.year, monthNumber - 1, day);
    if (date.getDay() === 0) {
      return "not-working-day";
    }

    date.setHours(0, 0, 0, 0);
    if (this.notWorkingDays.some(x => x === date.getTime())) {
      return "not-working-day";
    }
  }
}
