export const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

export const getNotWorkingDay = (year: number): Array<number> => {
  let a = year % 19;
  let b = Math.floor(year / 100);
  let c = year % 100;
  let d = Math.floor(b / 4);
  let e = b % 4;
  let f = Math.floor((b + 8) / 25);
  let g = Math.floor((b - f + 1) / 3);
  let h = (19 * a + b - d - g + 15) % 30;
  let i = Math.floor(c / 4);
  let k = c % 4;
  let l = (32 + 2 * e + 2 * i - h - k) % 7;
  let m = Math.floor((a + 11 * h + 22 * l) / 451);
  let p = (h + l - 7 * m + 114) % 31;
  let dzien = p + 1;
  let miesiac = Math.floor((h + l - 7 * m + 114) / 31) - 1;

  let wielkanoc = new Date(year, miesiac, dzien);
  let wielkanoc2 = new Date(year, miesiac, dzien + 1);
  let zds = new Date(year, miesiac, dzien + 49);
  let bc = new Date(year, miesiac, dzien + 60);

  return [
    new Date(year, 1 - 1, 1),
    new Date(year, 1 - 1, 6),
    new Date(year, 5 - 1, 1),
    new Date(year, 5 - 1, 3),
    new Date(year, 8 - 1, 15),
    new Date(year, 11 - 1, 1),
    new Date(year, 11 - 1, 11),
    new Date(year, 12 - 1, 25),
    new Date(year, 12 - 1, 26),
    wielkanoc,
    wielkanoc2,
    zds,
    bc
  ].map(x => {
    x.setHours(0, 0, 0, 0);
    return x.getTime()
  });
};

